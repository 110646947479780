export default function ApiConfig(type) {
  if (type === 'air') {
    return (
      'patcVl2jpQPhc3Swc.00f25982055049b8c4e781d2437c30d8a96986f1ee29c272bc367e8b9c7c5ef8'
    );
  } else {
    return (
      'e7a2a77635f91c67e94629fa9f0e0098'
    );
  }
}
