import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import ApiConfig from './config'
import { saveAs } from 'file-saver';


/* Sharing stuff */
import * as htmlToImage from 'html-to-image';
import {toJpeg, toCanvas} from 'html-to-image';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  PocketIcon,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TwitterShareButton,
  XIcon,
} from "react-share";

import exampleImage from "./assets/radnolan.png";

import radnolan from './assets/radnolan.png'
import logo from './assets/logo.svg'
import link from './assets/link.svg'
import random from './assets/random.svg'
import randomMod from './assets/random-mod.svg'
import blob1 from './assets/blob-01.svg'
import blob2 from './assets/blob-02.svg'
import closeX from './assets/x-circle.svg'

export default class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      email: "",
      modal: false,
      modifier: {
        Hue: 0,
        Name: ""
      },
      concept: {
        Hue: 0,
        Name: ""
      },
      prompts: {},
      shareImg: '',
      blob: '',
    }
  }

  updateUserState = () => {
    axios.get(
      "https://api.airtable.com/v0/appcvcl4CQk03zd8d/Prompts",
      {
        headers: {
          Authorization: "Bearer " + ApiConfig("air"),
        },
      }
    )
    .then(async (response) => {
      console.log(response.data);

      let thePrompts = {
        concepts: [],
        modifiers: []
      }
      for (var i in response.data.records) {
        if (response.data.records[i].fields.Type === 'Concept') {
          thePrompts.concepts.push(response.data.records[i].fields);
        } else {
          thePrompts.modifiers.push(response.data.records[i].fields);
        }
      }

      let modifier = thePrompts.modifiers[Math.floor(Math.random()*thePrompts.modifiers.length)];
      console.log("modifier");
      console.log(modifier);
      let concept = thePrompts.concepts[Math.floor(Math.random()*thePrompts.concepts.length)];

      this.setState({
        prompts: thePrompts,
        modifier: modifier,
        concept: concept,
      });

    })
  }

  shareModal = () => {
    console.log('share');
    if (this.state.modal) {
      this.setState({
        modal: false
      })
    } else {
      this.setState({
        modal: true
      })

      let theBlob = this.state.blob;

      setTimeout((function() {
        // htmlToImage.toBlob(document.getElementById('shareBox'))
        // .then(function (blob) {
        //   console.log(blob);
        //   theBlob = blob;
        // });

        // htmlToImage.toJpeg(document.getElementById('shareBox'), { quality: 0.95 })
        // .then(function (dataUrl) {
        //   var img = new Image();
        //   img.src = dataUrl;
        //   document.body.appendChild(img);
        //   // var link = document.createElement('a');
        //   // link.download = 'my-image-name.jpeg';
        //   // link.href = dataUrl;
        //   // link.click();
        // });

        // htmlToImage.toCanvas(document.getElementById('shareBox'))
        // .then(function (canvas) {
        //   canvas.id = 'someId';
        //   document.body.appendChild(canvas);
        // });

        this.captureImg();
      }).bind(this), 700);







    }
  }

  captureImg = () => {
    if (this.state.modal) {

    // htmlToImage.toJpeg(document.getElementById('shareBox'), { quality: 0.95 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'my-image-name.jpeg';
    //     link.href = dataUrl;
    //     link.click();
    //   });
    let name = 'prompt-' + this.state.modifier.diff + '-' + this.state.concept.diff + '.png';

      htmlToImage.toBlob(document.getElementById('shareBox'))
      .then(function (blob) {
        if (window.saveAs) {
          window.saveAs(blob, name);
        }

      });

      setTimeout((function() {
        this.shareModal();
      }).bind(this), 1000);
    }
  }

  randomPrompt = (t) => {
    let modifier = this.state.modifier;
    let concept = this.state.concept;

    if (t === 'both' || t === 'modifier') {
      modifier = this.state.prompts.modifiers[Math.floor(Math.random()*this.state.prompts.modifiers.length)];
      if (modifier === this.state.modifier) {modifier = this.state.prompts.modifiers[Math.floor(Math.random()*this.state.prompts.modifiers.length)];}
    }
    if (t === 'both' || t === 'concept') {
      concept = this.state.prompts.concepts[Math.floor(Math.random()*this.state.prompts.concepts.length)];
      if (concept === this.state.concept) {concept = this.state.prompts.concepts[Math.floor(Math.random()*this.state.prompts.concepts.length)];}
    }

    this.setState({
      modifier: modifier,
      concept: concept,
    });
  }

  updateNumbs = () => {
    console.log('clicked!');
    this.setState({
      x: 0,
      y: 0,
    });
    setTimeout((function() {
      this.forceUpdate();
    }).bind(this), 10);
  }




  componentDidMount() {
    this.updateUserState();
  }
  // Render
  // ----------------------------------------------------
  render() {
    let an = "a";
    if (this.state.modifier && this.state.modifier.An) {
      an = "an";
    }

    let dashClass = "Dash";
    if (this.state.modal) {
      dashClass += ' Sharing'
    }

    return (
      <div className={dashClass}>

        <img src={closeX} onClick={()=>this.shareModal()} className="closeIt" />

        <div className="wrapper" id="shareBox">
          {this.theNav}

          <div className="bgBlobs">
            <svg className="blob whiteBlob" width="1008" height="1120" viewBox="0 0 1008 1120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M833.573 597.119C1135.43 855.437 1044.96 929.242 561.312 1102.22C438.711 1161.04 313.572 1054.94 132.628 993.821C-48.3151 932.701 -22.9492 642.094 82.7421 208.489C188.433 -225.116 434.483 124.305 426.027 312.277C417.572 500.25 531.719 338.801 833.573 597.119Z" fill={"hsl(" + this.state.modifier.Hue + ", 100%, 86%)"}/>
            </svg>

            <svg className="blob" width="1586" height="1349" viewBox="0 0 1586 1349" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1441.67 993.581C1069.15 562.836 1568.9 653.857 861.872 186.906C683.532 54.9195 491.641 71.3622 219.655 11.1639C-52.3313 -49.0344 -31.9611 231.629 99.0511 693.821C230.063 1156.01 1107.47 1474.25 1106.09 1297.8C1104.71 1121.35 1886.78 1508.26 1441.67 993.581Z" fill={"hsl(" + this.state.concept.Hue + ", 88%, 67%)"}/>
            </svg>


          </div>
          <div className="Generator">
            <div className="nolanSays">
              <img className="nolan" src={radnolan} />
              <p>RadNolan says:</p>
            </div>

            <h2>Design {an}&nbsp;

                <span onClick={()=>this.randomPrompt('modifier')} className="Modifier" style={{color: "hsl(" + this.state.modifier.Hue + ", 100%, 90%)",borderColor: "hsl(" + this.state.modifier.Hue + ", 100%, 90%)"}}>
                  {this.state.modifier.Name}&nbsp;
                  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.41016 6.1416V14.604H9.87256" stroke={"hsl(" + this.state.modifier.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M33.439 28.708V20.2456H24.9766" stroke={"hsl(" + this.state.modifier.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M28.8989 13.1934C28.1836 11.172 26.9678 9.36477 25.3651 7.94029C23.7625 6.51581 21.825 5.52053 19.7337 5.04733C17.6423 4.57413 15.4651 4.63843 13.4053 5.23423C11.3455 5.83003 9.47024 6.93791 7.95441 8.4545L1.41016 14.6038M32.439 20.2454L25.8947 26.3948C24.3789 27.9114 22.5036 29.0193 20.4438 29.6151C18.384 30.2109 16.2068 30.2752 14.1154 29.802C12.0241 29.3288 10.0867 28.3335 8.48397 26.909C6.88129 25.4845 5.66557 23.6772 4.95026 21.6558" stroke={"hsl(" + this.state.modifier.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </span>&nbsp;
                <span onClick={()=>this.randomPrompt('concept')} className="Idea" style={{color: "hsl(" + this.state.concept.Hue + ", 100%, 90%)",borderColor: "hsl(" + this.state.concept.Hue + ", 100%, 90%)"}}>
                  {this.state.concept.Name}&nbsp;
                  <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.41016 6.1416V14.604H9.87256" stroke={"hsl(" + this.state.concept.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M33.439 28.708V20.2456H24.9766" stroke={"hsl(" + this.state.concept.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M28.8989 13.1934C28.1836 11.172 26.9678 9.36477 25.3651 7.94029C23.7625 6.51581 21.825 5.52053 19.7337 5.04733C17.6423 4.57413 15.4651 4.63843 13.4053 5.23423C11.3455 5.83003 9.47024 6.93791 7.95441 8.4545L1.41016 14.6038M32.439 20.2454L25.8947 26.3948C24.3789 27.9114 22.5036 29.0193 20.4438 29.6151C18.384 30.2109 16.2068 30.2752 14.1154 29.802C12.0241 29.3288 10.0867 28.3335 8.48397 26.909C6.88129 25.4845 5.66557 23.6772 4.95026 21.6558" stroke={"hsl(" + this.state.concept.Hue + ", 100%, 90%)"} stroke-width="2.8208" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                </span>
            </h2>
          </div>
        </div>

        <div className="joinNow">
          <a href="https://radnolan.com/70rad" class="absLink" target="_blank"></a>
          <p>Join the challenge! <img src={link} /></p>
        </div>
      </div>
    );
  }


  get theShares() {
    const shareUrl = "http://70rad.com";
    const title = "70Rad Design Challenge";


    if (this.state.modal) {
      return(
        <div className="Share_container">
          <div className="Share_some-network">
            <FacebookShareButton
              url={shareUrl}
              className="Share_some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>

          <div className="Share_some-network">
            <TwitterShareButton
              url={shareUrl}
              title={title}
              className="Share_some-network__share-button"
            >
              <XIcon size={32} round />
            </TwitterShareButton>
          </div>
          <div className="Share_some-network">
            <LinkedinShareButton
              url={shareUrl}
              className="Share_some-network__share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>

          <div className="Share_some-network">
            <PinterestShareButton
              url={String(window.location)}
              media={`${String(window.location)}/${exampleImage}`}
              className="Share_some-network__share-button"
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
          </div>

          <div className="Share_some-network">
            <RedditShareButton
              url={shareUrl}
              title={title}
              windowWidth={660}
              windowHeight={460}
              className="Share_some-network__share-button"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>

            <div>
              <RedditShareCount
                url={shareUrl}
                className="Share_some-network__share-count"
              />
            </div>
          </div>

          <div className="Share_some-network">
            <EmailShareButton
              url={shareUrl}
              subject={title}
              body="body"
              className="Share_some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>

          <div onClick={()=>this.captureImg()}>Download</div>
        </div>
      )
    }
  }

  get theNav() {
    if (!this.state.modal) {
      return (
        <div className="Nav">
          <div className="top">
            <img src={logo} />
          </div>

          <div className="menu">

          </div>

          <div className="btn" onClick={()=>this.shareModal()}>
            Share
          </div>
          <div className="btn randomize" onClick={()=>this.randomPrompt('both')}>
            <img src={random} /> Randomize
          </div>
        </div>
      );
    } else {
      return (
        <div className="Nav">
          <div className="top">
            <img src={logo} />
          </div>
        </div>
      );
    }
  }
}
