import React, { Component, PureComponent } from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ApiConfig from './config'
import axios from 'axios';

import Generator from './Generator';


// axios.defaults.headers.common['Authorization'] = 'Bearer ' + ApiConfig('air');
// axios.defaults.headers.common['x-api-key'] = ApiConfig('aws');

// axios.defaults.headers.common['Origin'] = 'casino.strandedverse.com';
// axios.defaults.headers.common['Content-Type'] = 'application/json';

// console.log(axios.defaults.headers);

class App extends Component {

  // Render
  // ----------------------------------------------------
  render() {

    return (

      <Router>
        <Routes>
          <Route exact path="/" element={<Generator/>} />
        </Routes>
      </Router>

    );
  }
}

export default App;
